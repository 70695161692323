import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { StyleIconSize, type FullAgent } from "@/types/agent";
import { isString } from "lodash";
import { useRef } from "react";
import { Controller, type UseFormReturn } from "react-hook-form";

type BubbleDisplayProps = {
  form: UseFormReturn<FullAgent["publish"]>;
  setImage: (src: string, name: string) => void;
};

export const BubbleDisplay = ({ form, setImage }: BubbleDisplayProps) => {
  const { control, watch } = form;
  const avatarWatcher = watch("chatStyle.image");
  const avatarUrl =
    avatarWatcher && !isString(avatarWatcher) ? URL.createObjectURL(avatarWatcher).toString() : avatarWatcher;

  const inputRef = useRef<HTMLInputElement>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name || "";
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result?.toString() || "", fileName));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <Label className="mb-2 text-neutral-400">Bubble Display</Label>
      <Controller
        control={control}
        name="chatStyle.iconSize"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            onValueChange={onChange}
            defaultValue={value}
            className="grid grid-cols-3 place-items-stretch gap-4 rounded-md bg-neutral-50 px-6 py-4 sm:grid-cols-[100px_100px_100px_1fr]"
          >
            <label className="flex cursor-pointer flex-col items-center justify-end gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatarUrl} size="full" className="size-12 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Small} />
                Small
              </span>
            </label>
            <label className="flex cursor-pointer flex-col items-center justify-end gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatarUrl} size="full" className="size-16 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Medium} />
                Medium
              </span>
            </label>
            <label className="flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatarUrl} size="full" className="size-20 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Large} />
                Large
              </span>
            </label>

            <div
              onClick={() => inputRef.current?.click()}
              className="col-span-full flex cursor-pointer flex-col items-center justify-center gap-1.5 rounded-md border border-dashed border-neutral-300 p-2 text-center text-neutral-400 sm:col-span-1"
            >
              <input hidden ref={inputRef} type="file" accept="image/*" onChange={onSelectFile} />
              <Icons.Upload className="size-6" />
              <span className="text-sm font-medium underline">Click to upload avatar</span>
            </div>
          </RadioGroup>
        )}
      />
    </div>
  );
};
