import { Label } from "@/components/ui/label";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Textarea } from "@/components/ui/textarea";
import { get } from "lodash";

export const AgentBio = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useAgentFormContext();

  const placeholderText = `Define the agent character. How should it behave? What to avoid? (e.g. You are a sales person for company XXX. You have a good sense of humor! You should avoid talking politics.)`;

  return (
    <div>
      <div className="flex items-center gap-1 pb-2">
        <Label htmlFor="inMemoryKnowledge.about">Agent Bio</Label>
        <HybridTooltipPopover heading="Craft Your Agent's Identity:">
          <p>
            Give your Agent a unique personality and backstory. It helps create a more engaging experience for users.
            Think of it as introducing your bot to the world!
          </p>
        </HybridTooltipPopover>
      </div>

      <Textarea
        id="inMemoryKnowledge.about"
        register={register("inMemoryKnowledge.about")}
        placeholder={placeholderText}
        maxLength={2000}
        error={get(errors, "inMemoryKnowledge.about.message")}
        formValue={watch("inMemoryKnowledge.about")}
      />
    </div>
  );
};
