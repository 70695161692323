import { Icons } from "@/components/ui/icons";
import { useInputForm } from "../hooks/useInputForm";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { memo } from "react";
import type { ChatAgent } from "@/types/conversation";
import Chip from "@/components/ui/Chip";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { cn } from "@/lib/utils";
import { ChatFileInput } from "./ChatFileInput";
import { ChatFileList } from "./ChatFileList";
import { inputDefaultClassName } from "@/components/ui/input";
import { ChatSelectedKnowledgeBadge } from "./ChatSelectedKnowledgeBadge";
import { VoiceMessageBars } from "./VoiceMessageBars";
import { VoiceMessageButton } from "./VoiceMessageButton";
import useAudioRecorder, { MAX_DURATION_IN_SECONDS } from "../hooks/useAudioRecorder";
import { useStopSSEResponse } from "@/data/mutations/useStopSSEResponse";

const _ChatInputForm = ({ agents }: { agents: ChatAgent[] }) => {
  const {
    messageText,
    textareaRef,
    setMessageText,
    handleComposition,
    submitSendMessage,
    isTagListVisible,
    agentsToTag,
    handleAddMessageRecipient,
    handleRemoveMessageRecipient,
    highlightedTagIndex,
  } = useInputForm({
    agents,
  });
  const { messageRecipients, isSendingMessage, isFileUploading, files, responseRequestId, setResponseRequestId } =
    useChatContext();
  const isChatEmpty = !messageText.trim().length && !files.length;

  const audioRecorder = useAudioRecorder({ submitSendMessage });

  const { mutate: stopSSEResponse } = useStopSSEResponse();

  const stop = () => {
    if (!responseRequestId) {
      return;
    }
    setResponseRequestId(null);
    stopSSEResponse(responseRequestId);
  };

  return (
    <div className="mx-auto mt-4 w-full">
      {!isSendingMessage && messageRecipients.length > 0 && (
        <div className="mb-4 flex items-center gap-2 px-4">
          <p className="text-xs font-medium">Tagged agents: </p>
          {messageRecipients.map(recipient => (
            <Chip
              key={recipient._id}
              text={recipient.name}
              canDelete
              onDeleteClick={() => {
                handleRemoveMessageRecipient(recipient._id);
              }}
            />
          ))}
        </div>
      )}
      <div className={cn(inputDefaultClassName, "relative mx-4 flex w-auto flex-col gap-2 !p-0")}>
        <ChatFileList />
        <ChatSelectedKnowledgeBadge />
        <form className="flex min-h-[56px] w-full items-end gap-2 p-2">
          {audioRecorder.isRecording ? (
            <>
              <Button
                className="min-w-10"
                type="button"
                variant="ghost"
                size="icon"
                onClick={audioRecorder.cancelRecording}
              >
                <Icons.Trash className="size-6 text-neutral-400" />
                <span className="sr-only">Cancel recording</span>
              </Button>
              <VoiceMessageBars className="mb-1" />
              <span className="mb-3 min-w-7 text-xs font-medium text-neutral-400">
                {audioRecorder.recordingTime < MAX_DURATION_IN_SECONDS
                  ? `0:${String(audioRecorder.recordingTime).padStart(2, "0")}`
                  : "1:00"}
              </span>
            </>
          ) : (
            <>
              <ChatFileInput agentsIds={agents.map(agent => agent._id)} />
              <Textarea
                containerClassName="self-center"
                autoFocus
                value={messageText}
                ref={textareaRef}
                onChange={e => setMessageText(e.target.value)}
                className="max-h-[30dvh] min-h-0 w-full resize-none !border-0 !px-0 py-1.5 font-medium text-primary-black !ring-0 !ring-offset-0"
                placeholder="Type your message here..."
                onCompositionEnd={handleComposition}
                onCompositionStart={handleComposition}
                maxLength={10000}
                hideMaxLengthLabel
              />
            </>
          )}
          {isChatEmpty ? (
            isSendingMessage ? (
              <Button disabled={!responseRequestId} onClick={stop} className="min-w-10" type="button" size="icon">
                <Icons.StopConversation className="size-5 min-w-5" />
                <span className="sr-only">Stop conversation</span>
              </Button>
            ) : (
              <VoiceMessageButton
                disabled={isSendingMessage}
                audioRecorder={audioRecorder}
                submitSendMessage={submitSendMessage}
              />
            )
          ) : (
            <Button
              className="min-w-10"
              type="button"
              size="icon"
              disabled={isSendingMessage || isFileUploading}
              onClick={() => submitSendMessage(messageText)}
            >
              <Icons.ArrowUp className="size-5 min-w-5" />
              <span className="sr-only">Send message</span>
            </Button>
          )}
          {isTagListVisible && (
            <ul className="absolute bottom-full mb-6 rounded-md border border-slate-200 bg-white p-1 text-slate-950 shadow-md">
              {agentsToTag.map((agent, index) => (
                <li
                  key={agent._id}
                  role="option"
                  onClick={() => handleAddMessageRecipient(agent)}
                  className={cn(
                    "my-0 flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-primary-100",
                    {
                      "bg-primary-100": highlightedTagIndex === index,
                    }
                  )}
                >
                  {agent.name}
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>
    </div>
  );
};
export const ChatInputForm = memo(_ChatInputForm);
