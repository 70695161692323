import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "../ui/input";
import { ChatHistoryDialogFoundBadge } from "./components/ChatHistoryDialogFoundBadge";
import StatsDateRangePicker from "../StatsDateRangePicker/StatsDateRangePicker";
import { HybridTooltipPopover } from "../HybridTooltipPopover";
import { Slider } from "../ui/slider";
import type { DateRangeType } from "@/types/datepicker";
import { cn } from "@/lib/utils";
import { Checkbox } from "../ui/checkbox";
import {
  MESSAGES_SLIDER_VALUES,
  MessagesNumRanges,
  messagesNumRanges,
  type MessagesNumRange,
} from "./constants/chatHistory";
import { useMemo } from "react";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";

type Props = {
  header?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;

  searchValue: string;
  onSearchValueChange: (value: string) => void;
  showSearchMatches: boolean;
  totalSearchMatches: number;
  debouncedSearchValue: string;
  currentRange: DateRangeType;
  onChangeCurrentRange: (value: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (value: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (value: Date | null) => void;
  selectedMessagesNumRange: MessagesNumRange;
  onSelectedMessagesRangeChange: (value: MessagesNumRange) => void;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (value: boolean) => void;
  customMinNumOfMessages: number | null;
  onCustomMinNumOfMessagesChange: (value: number | null) => void;
  customMaxNumOfMessages: number | null;
  onCustomMaxNumOfMessagesChange: (value: number | null) => void;
};

export const ChatHistoryDialog = ({
  header,
  isOpen,
  onClose,
  children,
  searchValue,
  onSearchValueChange,
  showSearchMatches,
  totalSearchMatches,
  debouncedSearchValue,
  currentRange,
  onChangeCurrentRange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  selectedMessagesNumRange,
  onSelectedMessagesRangeChange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  customMinNumOfMessages,
  onCustomMinNumOfMessagesChange,
  customMaxNumOfMessages,
  onCustomMaxNumOfMessagesChange,
}: Props) => {
  const sliderMinValue = useMemo(() => {
    return customMinNumOfMessages === null ? 0 : MESSAGES_SLIDER_VALUES.indexOf(customMinNumOfMessages);
  }, [customMinNumOfMessages]);

  const sliderMaxValue = useMemo(() => {
    return customMaxNumOfMessages === null
      ? MESSAGES_SLIDER_VALUES.length - 1
      : MESSAGES_SLIDER_VALUES.indexOf(customMaxNumOfMessages);
  }, [customMaxNumOfMessages]);

  const handleSelectedMessagesRangeChange = (value: MessagesNumRange) => {
    onSelectedMessagesRangeChange(value);

    onCustomMaxNumOfMessagesChange(null);

    if (value === MessagesNumRanges.AtLeast10) {
      onCustomMinNumOfMessagesChange(10);
    } else if (value === MessagesNumRanges.AtLeast5) {
      onCustomMinNumOfMessagesChange(5);
    } else {
      onCustomMinNumOfMessagesChange(null);
    }
  };

  const handleSliderChange = (values: number[]) => {
    const [min, max] = values;
    const minVal = MESSAGES_SLIDER_VALUES[min];
    const maxVal = MESSAGES_SLIDER_VALUES[max];

    onCustomMinNumOfMessagesChange(typeof minVal === "string" || minVal === 0 ? null : minVal);
    onCustomMaxNumOfMessagesChange(typeof maxVal === "string" ? null : maxVal);
  };

  const isScreenXl = useTailwindMediaQuery("xl");

  if (isScreenXl) {
    return (
      <Dialog open={isOpen} onOpenChange={() => onClose()}>
        <DialogContent variant="xl">
          <DialogHeader className="h-14 px-12 py-4 font-bold text-primary-black">
            {header ?? "Conversations"}
          </DialogHeader>
          <div className="h-full w-full px-12 pb-16 pt-6">
            <div className="flex items-center justify-between pb-10">
              <div className="w-full">
                <Input
                  value={searchValue}
                  onChange={e => onSearchValueChange(e.target.value)}
                  placeholder="Search keywords or name"
                  inputClassName={cn("placeholder:text-slate-400 !pr-9 h-10")}
                  handleClear={() => onSearchValueChange("")}
                />

                {showSearchMatches && (
                  <ChatHistoryDialogFoundBadge
                    foundValue={totalSearchMatches}
                    searchValue={debouncedSearchValue}
                    className="absolute mt-3"
                  />
                )}
              </div>
              <span className="mx-4">
                <Checkbox
                  id="downvotedMessages"
                  label="Downvoted only"
                  checked={isOnlyDownvotedMessages}
                  onCheckedChange={e => {
                    onIsOnlyDownvotedMessagesChange(e);
                  }}
                  labelClassName="text-nowrap"
                />
              </span>
              <div className="mx-4">
                <StatsDateRangePicker
                  selectedDateRangeType={currentRange}
                  onSelectedDateRangeTypeChange={onChangeCurrentRange}
                  startDate={startDate}
                  onStartDateChange={onStartDateChange}
                  endDate={endDate}
                  onEndDateChange={onEndDateChange}
                  align="start"
                />
              </div>
              <div className="ml-3 mr-1">
                <Select
                  onValueChange={value => {
                    handleSelectedMessagesRangeChange(value as MessagesNumRange);
                  }}
                  value={selectedMessagesNumRange}
                >
                  <SelectTrigger id="numOfMessages" className="w-[209px]">
                    <SelectValue placeholder=""></SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {messagesNumRanges?.map(rangeType => {
                      return (
                        <SelectItem key={rangeType} value={rangeType}>
                          {rangeType}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="flex h-full max-h-[calc(100%-98px)] flex-1 grow flex-col gap-2 overflow-y-scroll">
              {children}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button size="md" variant="secondary">
                Done
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent variant="large">
        <DialogHeader className="font-bold text-primary-black">{header ?? "Conversations"}</DialogHeader>
        <div className="flex h-full flex-col gap-6 px-5 py-4 pb-20 lg:flex-row lg:px-8">
          <div className="flex min-w-52 flex-col lg:max-w-52">
            <div className="relative">
              <Input
                value={searchValue}
                onChange={e => onSearchValueChange(e.target.value)}
                placeholder="Search keywords or name"
                inputClassName="placeholder:text-slate-400 !pr-9 !sm:pr-9 h-10"
                handleClear={() => onSearchValueChange("")}
              />
              {showSearchMatches && (
                <ChatHistoryDialogFoundBadge
                  foundValue={totalSearchMatches}
                  searchValue={debouncedSearchValue}
                  className="mt-1"
                />
              )}
            </div>
            <div className="mt-4">
              <p className="mb-2 text-left text-sm font-medium text-neutral-400">Filter by</p>
              <StatsDateRangePicker
                selectedDateRangeType={currentRange}
                onSelectedDateRangeTypeChange={onChangeCurrentRange}
                startDate={startDate}
                onStartDateChange={onStartDateChange}
                endDate={endDate}
                onEndDateChange={onEndDateChange}
                align="start"
              />
            </div>

            <div className="my-4">
              <div className="mb-2 flex items-center gap-1">
                <p className="text-left text-sm font-medium text-neutral-400">Number of messages</p>
                <HybridTooltipPopover>
                  <p>By moving around this slider, you will only see the conversations within that range of messages</p>
                </HybridTooltipPopover>
              </div>
              <Select
                onValueChange={value => {
                  handleSelectedMessagesRangeChange(value as MessagesNumRange);
                }}
                value={selectedMessagesNumRange}
              >
                <SelectTrigger id="numOfMessages" className="w-full">
                  <SelectValue placeholder=""></SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {messagesNumRanges?.map(rangeType => {
                    return (
                      <SelectItem key={rangeType} value={rangeType}>
                        {rangeType}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>

              {selectedMessagesNumRange === MessagesNumRanges.Custom && (
                <div className="mt-8 pb-6">
                  <Slider
                    value={[sliderMinValue, sliderMaxValue]}
                    minStepsBetweenThumbs={1}
                    step={1}
                    min={0}
                    max={MESSAGES_SLIDER_VALUES.length - 1}
                    onValueChange={handleSliderChange}
                  />
                  <div className="relative mt-2">
                    {MESSAGES_SLIDER_VALUES?.map((value, index) => {
                      const isFirst = index === 0;
                      const isLast = index === MESSAGES_SLIDER_VALUES.length - 1;

                      const numOfSections = MESSAGES_SLIDER_VALUES.length - 1;
                      const offsetLeft = isFirst ? 0 : (index / numOfSections) * 100;
                      const thumbWidth = 16;
                      const isNumOfSectionsEven = numOfSections % 2 === 0;
                      const thumbOffset = isNumOfSectionsEven
                        ? thumbWidth / numOfSections
                        : thumbWidth / numOfSections / 2;
                      const isLeftHalf = index < numOfSections / 2;
                      const left = `calc(${offsetLeft}% ${isLeftHalf ? "+" : "-"} ${thumbOffset}px)`;

                      return (
                        <span
                          key={index}
                          className={cn("absolute -translate-x-1/2", {
                            "-translate-x-full": isLast,
                          })}
                          style={{
                            left,
                          }}
                        >
                          {value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className="my-4">
              <Checkbox
                id="downvotedMessages"
                label="Show only chats with downvoted messages"
                checked={isOnlyDownvotedMessages}
                onCheckedChange={e => {
                  onIsOnlyDownvotedMessagesChange(e);
                }}
              />
            </div>
          </div>

          <div className="flex h-full max-h-full flex-1 grow flex-col gap-2 overflow-y-scroll">{children}</div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="md" variant="secondary">
              Done
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
